import React, { useRef, useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import { InView } from 'react-intersection-observer'
import typographySizes from 'src/assets/styles/typographySizes'

import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import Seo from 'src/components/global/Seo.js'

import TitleH1 from 'src/components/global/texts/TitleH1.js'
import mediaQuery from 'src/assets/styles/mediaQuery'
import TextBarAnimationLegacy from 'src/components/global/TextBarAnimationLegacy.js'

const StyledMain = styled.main`
  background-color: ${colors.black};
  width: 100vw;
  padding-top: 100rem;
  position: relative;

  ${MainWrapper} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${TitleH1} {
    color: white;
    font-size: ${typographySizes.l}rem;
    margin: 0 20rem;
    margin-top: 50rem;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.m}rem;
      margin-top: 100rem;
    }
  }
  h2 {
    color: ${colors.mainColor};
    font-size: ${typographySizes.m}rem;
    z-index: 10;
    margin-top: 20rem;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.s}rem;
    }
  }
`

const Vid = styled.div`
  width: 90vw;
  max-width: 900rem;
  z-index: 10;
  margin-top: 50rem;
  overflow: hidden;
  position: relative;

  :last-of-type {
    margin-bottom: 100rem;
  }

  ::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const TextBarAnimationWrapper = styled.div`
  /* left: -20rem;
  position: relative; */
  pointer-events: none;

  @media (max-width: ${mediaQuery.tablet}) {
    position: absolute;
    height: 100%;
    pointer-events: none;
    width: 100vw;
    top: 0;
    left: -10rem;
  }
`

const IndexPage = () => {
  return (
    <>
      <Seo title="LEGACY" description="LEGACY " />
      <StyledMain>
        <MainWrapper>
          <Lines
            leftMargin
            left
            leftCenter
            center
            rightCenter
            right
            rightMargin
          />
          <TitleH1>NOBOCOTO LEGACY</TitleH1>
          <h2>S01</h2>
          <div>
            <Vid>
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/DqQ2ra0DuFA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Vid>
            <Vid>
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/-99NuM0ga_I"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Vid>
            <Vid>
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/agOhKhX-LTk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Vid>
            <Vid>
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/agOhKhX-LTk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Vid>
          </div>
          <TextBarAnimationWrapper>
            <TextBarAnimationLegacy />
          </TextBarAnimationWrapper>
        </MainWrapper>
      </StyledMain>
    </>
  )
}

export default IndexPage
